import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import AutoJoinDomainModel from 'teamtailor/models/auto-join-domain';
import SettingsAutoJoinRoute from 'teamtailor/routes/settings/auto-join';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FlipperService from 'teamtailor/services/flipper';

export default class SettingsAutoJoinController extends Controller {
  declare model: ModelFrom<SettingsAutoJoinRoute>;

  @service declare current: Current;
  @service declare store: Store;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  get autoJoinDomains() {
    return this.model.filter((item) => !item.isDeleted);
  }

  get autoJoinErrors() {
    return this.current.company.errors.filter(
      (error) => error.attribute === 'auto_join_domains.domain'
    );
  }

  @action
  async handleSave() {
    try {
      await this.current.company.save();

      this.current.company.autoJoinDomains
        .filter((domain) => domain.isNew)
        .forEach((domain) => domain.unloadRecord());

      this.flashMessages.success(
        this.intl.t('settings.auto_join.auto_join_domains_saved')
      );
    } catch {
      this.flashMessages.error(
        this.intl.t('settings.auto_join.failed_to_save_auto_join_domains')
      );
    }
  }

  @action
  handleRemoveAutoJoinDomain(autoJoinDomain: AutoJoinDomainModel) {
    autoJoinDomain.deleteRecord();
  }

  @action
  handleCreateAutoJoinDomain() {
    this.store.createRecord('auto-join-domain', {
      company: this.current.company,
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.auto-join': SettingsAutoJoinController;
  }
}

import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { get } from 'teamtailor/utils/get';
import { dasherize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';
import { marketingTypesInMarketplace } from 'teamtailor/constants/marketplace';
import currencyForCountry, {
  ALLOWED_CURRENCIES,
  DEFAULT_CURRENCY,
} from 'teamtailor/utils/currency-for-country';
import {
  getOriginalPromotionPriceForCountry,
  getDiscountedPromotionPriceForCountry,
} from 'teamtailor/utils/promotion-prices';
import {
  CUSTOM_FORM_CHANNELS,
  NON_EXISITNG_AGREEMENT_ADAPTER_CHANNELS,
} from 'teamtailor/constants/custom-form-channels';

export default class ChannelModel extends Model {
  @service intl;
  @service current;

  @belongsTo('channel-activation', { async: false })
  channelActivation;

  @hasMany('promotion') promotions;
  @hasMany('video', { async: true }) video;
  @attr('number') discountPriceSek;

  @attr('number') priceSek;
  @attr('number') discountPriceEur;
  @attr('number') priceEur;
  @attr('number') discountPriceGbp;
  @attr('number') priceGbp;
  @attr('number') discountPriceDkk;
  @attr('number') priceDkk;
  @attr('number') discountPriceUsd;
  @attr('number') priceUsd;
  @attr('number') duration;
  @attr('string') logotype;
  @attr('string') logotypeSquare;
  @attr('carrierwave-cache') logotypeCache;
  @attr('string') darkLogotype;
  @attr('string') name;
  @attr('number') rowOrder;
  @attr('string') templateName;
  @attr('string') description;
  @attr('string') pitch;
  @attr('string') country;
  @attr('string') category;
  @attr('string') presenterName;
  @attr('raw') countries;
  @attr('boolean') disabled;
  @attr('boolean') unlisted;
  @attr('raw') plans;
  @attr('boolean') showNoteSection;
  @attr('string') internalName;
  @attr('boolean') isSocial;
  @attr isBundle;
  @attr('boolean') isPremium;
  @attr('boolean') isFree;
  @attr('boolean') isAlwaysIncluded;
  @attr('boolean') isLeadPage;
  @attr('boolean') isInternal;
  @attr('string') marketingType;
  @attr('string') adapterBaseUrl;
  @attr('boolean') hasAdapter;
  @attr('boolean') canRefresh;
  @attr('boolean') canEdit;
  @attr('boolean') canRemove;
  @attr('string') activationType;
  @attr('raw') activationConfig;
  @attr('string') integrationDescription;
  @attr('string') supportContact;
  @attr('string') supportArticleUrl;
  @attr('boolean') createdByTeamtailor;
  @attr('raw') images;
  @attr('string') websiteUrl;
  @attr('boolean') nonPromotable;
  @attr('string') allowedPaymentTypes;
  @attr('string') outOfServiceMessage;

  get defaultDuration() {
    return get(this, 'channelActivation.duration') || this.duration;
  }

  set defaultDuration(value) {
    this.duration = value;
  }

  get socialPremiumFree() {
    return this.isSocial ? 'isFree' : 'isPremium';
  }

  get isSingleAds() {
    return ['partner_or_teamtailor', 'teamtailor'].includes(
      this.allowedPaymentTypes
    );
  }

  get translatedDescription() {
    if (this.intl.exists(`channels.${this.internalName}.description`)) {
      return this.intl.t(`channels.${this.internalName}.description`, {
        htmlSafe: true,
      });
    } else {
      return this.description;
    }
  }

  get combinedDescriptions() {
    if (this.integrationDescription)
      return htmlSafe(
        `<div class="flex flex-col gap-4"><span>${this.translatedDescription}</span><span>${this.integrationDescription}</span></div>`
      );

    return htmlSafe(this.translatedDescription);
  }

  get originalPromotionPrice() {
    return getOriginalPromotionPriceForCountry(this);
  }

  get discountedPromotionPrice() {
    return getDiscountedPromotionPriceForCountry(this);
  }

  get priceForCountry() {
    switch (this.countryForPromotion) {
      case 'Sweden':
        return this.discountPriceSek || this.priceSek;

      case 'United Kingdom':
        return this.discountPriceGbp || this.priceGbp;

      case 'Denmark':
        return this.discountPriceDkk || this.priceDkk;

      default:
        return this.discountPriceEur || this.priceEur;
    }
  }

  get redirectToAdapterRoute() {
    const adapterChannel =
      get(this, 'channelActivation.existingAgreement') ||
      NON_EXISITNG_AGREEMENT_ADAPTER_CHANNELS.includes(this.internalName);
    return adapterChannel && this.adapterBaseUrl;
  }

  get priceForCountryFormatted() {
    if (get(this, 'isAlwaysIncluded')) {
      return null;
    }

    if (this.marketingType === 'free') {
      return this.intl.t('models.channel.free');
    }

    if (
      ['no_payment', 'partner', 'charged_by_partner', null].includes(
        this.allowedPaymentTypes
      )
    ) {
      return this.intl.t('models.channel.agreed_with_partner');
    }

    if (!this.priceForCountry) return null;

    const priceToDisplay = `${this.priceForCountry} ${this.currency}`;

    return this.plans
      ? `${this.intl.t('common.from')} ${priceToDisplay}`
      : priceToDisplay;
  }

  get marketingTypeHumanName() {
    const marketingType = get(this, 'marketingType');

    if (marketingTypesInMarketplace.includes(marketingType)) {
      return this.intl.t(`marketplace.types.${marketingType}`);
    }

    throw new Error(`Unknown channel marketingType ${marketingType}`);
  }

  get isActivated() {
    if (get(this, 'isAlwaysIncluded')) {
      return !!get(this, 'channelActivation.activated');
    }

    return get(this, 'channelActivation.isNew') === false;
  }

  get canPause() {
    return ['indeed', 'indeed_oauth'].includes(get(this, 'internalName'));
  }

  get isAutoRefreshed() {
    return (
      !get(this, 'canEdit') &&
      !['apec', 'indeed', 'hainesattract'].includes(get(this, 'internalName'))
    );
  }

  get isEditDisabled() {
    return ['apec'].includes(get(this, 'internalName'));
  }

  get editDisabledTooltipText() {
    switch (get(this, 'internalName')) {
      case 'apec':
        return this.intl.t('models.channel', { email: 'offres@apec.fr' });
      default:
        return undefined;
    }
  }

  get defaultPromotionValues() {
    switch (get(this, 'internalName')) {
      case 'ams':
        return {
          amsFullTime: true,
          amsDuration: 1,
          amsExperienceRequired: false,
        };
      case 'blocket':
        return {
          blocketEmployment: 1,
        };
      case 'monster':
        return {
          monsterJobStatus: 4,
        };
      case 'metro':
        return {
          metroEmploymentType: 1,
        };
      case 'finn':
        return {
          finnJobType: 'job',
          finnManagerRole: 'senmanager',
          finnDuration: 'permanent',
          finnNoOfPositions: 1,
        };
      default:
        return {};
    }
  }

  get countryForPromotion() {
    return ['Global', 'Multiple countries'].includes(get(this, 'country'))
      ? get(this.current.company, 'headquarters.country')
      : get(this, 'country');
  }

  get currency() {
    if (get(this, 'internalName') === 'indeed') return 'EUR';

    const currency = currencyForCountry(get(this, 'countryForPromotion'));

    if (ALLOWED_CURRENCIES.includes(currency)) {
      return currency;
    }

    return DEFAULT_CURRENCY;
  }

  get hasCustomSettings() {
    const channelsWithCustomSettings = [
      'indeed',
      'indeed_always_included',
      'linkedin_limited_listings',
      'facebook_jobs',
    ].concat(CUSTOM_FORM_CHANNELS);
    return (
      channelsWithCustomSettings.includes(get(this, 'internalName')) ||
      get(this, 'allowedPaymentTypes') === 'partner_or_teamtailor'
    );
  }

  get isSocialOrBundle() {
    return this.isSocial || this.isBundle;
  }

  get isOrHasLinkedIn() {
    const { marketingType, internalName } = this;
    const description = this.description.toLowerCase();

    const isSocialLinkedIn =
      marketingType === 'social' && internalName.includes('linkedin');
    const hasBundleLinkedIn =
      marketingType === 'bundle' && description.includes('linkedin');

    return isSocialLinkedIn || hasBundleLinkedIn;
  }

  get hideCustomBudgetElement() {
    const hideCustomBudgetElementFor = ['duunitori', 'student_job'];
    return hideCustomBudgetElementFor.includes(get(this, 'internalName'));
  }

  get defaultActivationType() {
    return ['partner_or_teamtailor', 'teamtailor'].includes(
      this.allowedPaymentTypes
    )
      ? 'teamtailor'
      : 'existing_agreement';
  }

  get chargedByPartner() {
    return this.allowedPaymentTypes === 'charged_by_partner';
  }

  get discountInPercent() {
    if (!this.originalPromotionPrice || !this.discountedPromotionPrice)
      return null;

    return Math.round(
      ((this.originalPromotionPrice - this.discountedPromotionPrice) /
        this.originalPromotionPrice) *
        100
    );
  }

  get hasActivationConfig() {
    return !!this.activationConfig?.config?.fields?.length;
  }

  get activationFormComponent() {
    const componentPath = (componentName) =>
      `promotion/activation/${dasherize(componentName)}`;

    if (!CUSTOM_FORM_CHANNELS.includes(this.internalName)) return null;
    if (this.templateName === 'monster') return componentPath('monster');
    if (this.internalName.includes('cv_online'))
      return componentPath('cv_online');

    return componentPath(this.internalName);
  }

  get hasConfigurationForm() {
    return this.hasActivationConfig || !!this.activationFormComponent;
  }

  get shouldListInMarketplace() {
    return !this.unlisted && !this.disabled;
  }

  get isOutOfService() {
    return !!this.outOfServiceMessage;
  }
}

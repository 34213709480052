import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{@wrapperClass}}>\n  <Icon\n    @icon={{this.iconName}}\n    @style={{this.iconStyle}}\n    class={{class-names @iconClass (if this.mirror \"-scale-x-100 transform\")}}\n  />\n  {{#if (and @pageHasChanges @hasChanges)}}\n    <StatusDot\n      class=\"absolute -right-2 -top-2 cursor-pointer\"\n      @size=\"medium\"\n      @status=\"changed\"\n      @tooltip={{t\n        (concat\n          \"components.content_block.editor.item.\"\n          (if @userName \"changes_tooltip\" \"changes_tooltip_without_user\")\n        )\n        date=(format-datetime\n          @date\n          languageCode=this.languageCode\n          todayAtTranslation=(lowercase (t \"moment.today_at\"))\n        )\n        userName=@userName\n      }}\n      @tooltipClass=\"left-24\"\n      @tooltipSide=\"top\"\n    />\n  {{/if}}\n</div>", {"contents":"<div class={{@wrapperClass}}>\n  <Icon\n    @icon={{this.iconName}}\n    @style={{this.iconStyle}}\n    class={{class-names @iconClass (if this.mirror \"-scale-x-100 transform\")}}\n  />\n  {{#if (and @pageHasChanges @hasChanges)}}\n    <StatusDot\n      class=\"absolute -right-2 -top-2 cursor-pointer\"\n      @size=\"medium\"\n      @status=\"changed\"\n      @tooltip={{t\n        (concat\n          \"components.content_block.editor.item.\"\n          (if @userName \"changes_tooltip\" \"changes_tooltip_without_user\")\n        )\n        date=(format-datetime\n          @date\n          languageCode=this.languageCode\n          todayAtTranslation=(lowercase (t \"moment.today_at\"))\n        )\n        userName=@userName\n      }}\n      @tooltipClass=\"left-24\"\n      @tooltipSide=\"top\"\n    />\n  {{/if}}\n</div>","moduleName":"teamtailor/components/block-editor-item-icon.hbs","parseOptions":{"srcName":"teamtailor/components/block-editor-item-icon.hbs"}});
import Component from '@glimmer/component';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import {
  sectionIcon,
  TYPE_ICON_MAPPING,
} from 'teamtailor/helpers/section-icon';

interface Args {
  type: keyof typeof TYPE_ICON_MAPPING;
}

export default class BlockEditorItemIconComponent extends Component<Args> {
  @service declare current: Current;

  get languageCode() {
    return this.current.locale;
  }

  get iconName() {
    return sectionIcon(this.type).name;
  }

  get iconStyle() {
    return sectionIcon(this.type).style;
  }

  get mirror() {
    return sectionIcon(this.type).mirror;
  }

  get type() {
    return this.args.type;
  }
}

import Model from '@ember-data/model';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

/**
 * Get the resolved type of an item.
 *
 * - If the item is a promise, the result will be the resolved value type
 * - If the item is not a promise, the result will just be the type of the item
 */
export type Resolved<P> = P extends Promise<infer T> ? T : P;

/** Get the resolved model value from a route. */
export type ModelFrom<R extends Route> = Resolved<ReturnType<R['model']>>;

export type Transition = ReturnType<RouterService['transitionTo']>;

/** Get value types of object */
export type ValueOf<T> = T[keyof T];

export type Entries<T> = NonNullable<
  {
    [K in keyof T]: [K, T[K]];
  }[keyof T]
>[];

export type TypeLiteralOnly<T> = string extends T
  ? never
  : number extends T
    ? never
    : T;

export type RemoveIndexSignature<T> = {
  [P in keyof T as TypeLiteralOnly<P>]: T[P];
};

export type KeysOfUnion<T> = T extends T ? keyof T : never;

export type Nullish = null | undefined;

export type NonFunctionKeyNames<T> = Exclude<
  {
    [key in keyof T]: NonNullable<T[key]> extends Function ? never : key;
  }[keyof T],
  undefined
>;

export type RemoveFunctions<T> = Pick<T, NonFunctionKeyNames<T>>;

export const keys = Object.keys as <T>(obj: T) => Array<keyof T>;

export type OmitNullish<T> = {
  [K in keyof T as T[K] extends NonNullable<T[K]> ? K : never]: T[K];
};

// see: https://www.totaltypescript.com/concepts/the-prettify-helper
export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

export function isObjectWithKey<T, U extends T, K extends string>(
  a: unknown,
  key: K
): a is T & {
  [k in K]: K extends KeysOfUnion<U> ? U[K] : unknown;
} {
  return typeof a === 'object' && a !== null && key in a;
}

export type EmptyObj = Record<never, never>;

export type MaybeAsync<T extends Model | Model[]> =
  T extends (infer ArrayModelType)[]
    ? ArrayModelType extends Model
      ? DS.PromiseObject<DS.ManyArray<ArrayModelType>>
      : never
    : T;

import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { Transition } from 'teamtailor/utils/type-utils';
import SettingsIntegrationsWidgetsController from 'teamtailor/controllers/settings/integrations/widgets';

export default class WidgetsRoute extends ScrollToTopRoute {
  queryParams = {
    tab: { refreshModel: false },
  };

  resetController(
    controller: SettingsIntegrationsWidgetsController,
    isExiting: boolean,
    transition: Transition
  ) {
    super.resetController(controller, isExiting, transition);
    controller.tab = 'job-list';
  }
}

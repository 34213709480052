import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';

export default class HasFlipper extends Helper {
  @service declare flipper: FlipperService;

  compute([flagName]: [string]) {
    return flagName ? get(this.flipper, flagName) : false;
  }
}

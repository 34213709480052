import { tracked } from '@glimmer/tracking';
import CompanyModel from 'teamtailor/models/company';
import UserModel from 'teamtailor/models/user';
import { get } from 'teamtailor/utils/get';
import Service, { inject as service } from '@ember/service';
import { IntlService } from 'ember-intl';

export default class Current extends Service.extend({}) {
  @service declare intl: IntlService;

  @tracked declare company: CompanyModel;
  @tracked declare user: UserModel;

  get locale() {
    return (
      get(this, 'user.locale') ||
      get(this, 'company.localeAts') ||
      this.intl.primaryLocale
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    current: Current;
  }
}

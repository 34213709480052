import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import { timeout, dropTask, restartableTask } from 'ember-concurrency';
import { Promise } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { computedLocalStorage } from 'teamtailor/utils/computed-local-storage';
// eslint-disable-next-line ember/no-mixins
import SearchQuery from 'teamtailor/mixins/search-query';
import IntercomService from 'teamtailor/services/intercom';
import IntlService from 'ember-intl/services/intl';
import TtAlertService from 'teamtailor/services/tt-alert';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import FlipperService from 'teamtailor/services/flipper';
import Current from 'teamtailor/services/current';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import ContentIndexRoute from 'teamtailor/routes/content';
import { get } from 'teamtailor/utils/get';
import { PageModel, PagePublicationModel } from 'teamtailor/models';

export default class ContentIndexController extends Controller.extend(
  SearchQuery
) {
  declare model: ModelFrom<ContentIndexRoute>;

  @service declare intercom: IntercomService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare ttAlert: TtAlertService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare flipper: FlipperService;
  @service declare current: Current;

  queryParams = ['view', 'site', 'query'];

  declare resolve: () => void;
  declare reject: (x?: { cancel: true }) => void;

  @tracked view = null;
  @tracked site = null;
  @tracked query: string | null = '';
  @tracked showQrCodeCreator = false;
  @tracked showPublishChangesModal = false;

  @computedLocalStorage(Boolean, 'ContentIndex.showFilterSidebar', false)
  declare showFilterSidebar: boolean;

  get viewName() {
    return this.view;
  }

  get pages() {
    return get(this.model, 'pages');
  }

  get careerSite() {
    return (
      get(this.model, 'careerSite') ||
      get(this.current.company, 'defaultCareerSite')
    );
  }

  get homePage() {
    return get(this.careerSite, 'homePage');
  }

  get pageCount() {
    return get(this.careerSite, 'pageCount');
  }

  get hasDesignChanges() {
    return get(get(this.current.company, 'draftDesign'), 'unpublishedChanges');
  }

  get hasHeaderChanges() {
    return get(get(this.careerSite, 'careerSiteHeader'), 'unpublishedChanges');
  }

  get hasConnectChanges() {
    return get(get(this.careerSite, 'connectPage'), 'unpublishedChanges');
  }

  get hasUnpublishedPages() {
    return this.unpublishedPagesCount;
  }

  get hasUnpublishedChanges() {
    return !!(
      this.hasUnpublishedPages ||
      this.hasDesignChanges ||
      this.hasHeaderChanges ||
      this.hasConnectChanges
    );
  }

  get unpublishedPagesCount() {
    return get(this.pageCount, 'unpublished');
  }

  get confirmDialogText() {
    return this.intl.t(
      'content.index.publish_all_changes.description_only_pages',
      {
        count: get(this, 'unpublishedPagesCount'),
      }
    );
  }

  get unpublishedChangesCount() {
    const designChanges = this.hasDesignChanges ? 1 : 0;
    const headerChanges = this.hasHeaderChanges ? 1 : 0;
    const connectChanges = this.hasConnectChanges ? 1 : 0;

    return (
      (get(this, 'unpublishedPagesCount') || 0) +
      designChanges +
      headerChanges +
      connectChanges
    );
  }

  publishPage = (
    pagePublication: PagePublicationModel,
    onSuccess?: () => void
  ) => {
    const promise = pagePublication.publish();

    if (onSuccess) {
      promise.then(onSuccess);
    }

    return promise;
  };

  duplicatePageTask = dropTask(async (page) => {
    let toPage = await this.store.createRecord('page', {
      pageType: get(page, 'pageType'),
      status: 'clone',
      title: this.intl.t('content.editor.page_duplicate_title', {
        pageTitle: get(page, 'title'),
      }),

      careerSiteId: get(this.careerSite, 'id'),
    });

    await timeout(1500);

    toPage = await toPage.save();
    await page.duplicate({ to_page_id: toPage.id });
    get(this, 'flashMessages').success(
      this.intl.t('content.editor.page_duplicate_message')
    );
    this.router.transitionTo('content.editor.index', 'pages', toPage.id);
    page.status = 'draft';
  });

  publishPagePublication = dropTask(async (pagePublication) => {
    await pagePublication.publish();
  });

  unpublishPagePublication = dropTask(async (pagePublication) => {
    await pagePublication.unpublish();
  });

  publishPageChanges = async (pageIds: string[]) => {
    if (get(this, 'hasUnpublishedPages')) {
      const careerSite = await get(this, 'careerSite');
      const selectedPagePublications =
        await careerSite?.pagePublications.filter(
          (pagePublication: PagePublicationModel) =>
            pageIds.includes(pagePublication.id)
        );

      await selectedPagePublications?.forEach(
        (pagePublication: PagePublicationModel) => {
          pagePublication.promoteChanges();
        }
      );
    }
  };

  publishGlobalChanges = async (
    globalChange: boolean | string,
    hasGlobalChange: keyof this,
    content: unknown
  ) => {
    if (globalChange === true && get(this, hasGlobalChange)) {
      // @ts-expect-error not sture which keys content can be, set it to them?
      await get(this, content)?.publish();
    }
  };

  publishPageSettingsChanges = async (
    shouldPublishPageSettingsChanges: boolean
  ) => {
    if (shouldPublishPageSettingsChanges) {
      const careerSite = await get(this, 'careerSite');
      await careerSite?.promoteAllChanges();
    }
  };

  publishChangesTask = dropTask(
    async (
      selectedPagePublicationIds,
      selectedGlobalDesignChange,
      selectedGlobalBlockChange,
      shouldPublishPageSettingsChanges
    ) => {
      try {
        await this.publishPageSettingsChanges(shouldPublishPageSettingsChanges);
        await this.publishPageChanges(selectedPagePublicationIds);
        await this.publishGlobalChanges(
          selectedGlobalDesignChange,
          'hasDesignChanges',
          'current.company.draftDesign.content'
        );
        await this.publishGlobalChanges(
          selectedGlobalBlockChange,
          'hasHeaderChanges',
          'careerSite.careerSiteHeader.content'
        );

        if (this.hasConnectChanges) {
          const careerSite = await get(this, 'careerSite');
          const pagePublication = get(
            careerSite?.connectPage,
            'pagePublication'
          );

          if (pagePublication) {
            pagePublication.promoteChanges();
          }
        }

        this.resolve();
      } catch (e) {
        if (
          e &&
          typeof e === 'object' &&
          'message' in e &&
          typeof e.message === 'string'
        ) {
          this.flashMessages.error(e.message);
        }

        this.reject();
      }
    }
  );

  changeTemplateTask = restartableTask(async (page) => {
    const previousDefaultPage = (await this.pages).findBy('isDefault', true);
    page.isDefault = true;
    previousDefaultPage!.isDefault = false;

    try {
      await page.save();
    } catch (e) {
      page.isDefault = false;
      previousDefaultPage!.isDefault = true;
      if (e instanceof Error) {
        this.flashMessages.error(e.message);
      }
    }
  });

  @action
  confirmPublishModal(
    selectedPagePublicationIds: string[],
    selectedGlobalDesignChange: boolean,
    selectedGlobalBlockChange: string,
    shouldPublishPageSettingsChanges: boolean
  ) {
    this.publishChangesTask.perform(
      selectedPagePublicationIds,
      selectedGlobalDesignChange,
      selectedGlobalBlockChange,
      shouldPublishPageSettingsChanges
    );

    this.showPublishChangesModal = false;
  }

  @action
  closePublishModal(changeModalStatus: string) {
    this.reject({ cancel: true });
    if (changeModalStatus) {
      this.showPublishChangesModal = false;
    }
  }

  @action
  handlePublishAll() {
    this.showPublishChangesModal = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  @action
  handlePageStatusChange(pagePublication: PagePublicationModel) {
    if (!get(pagePublication, 'publishedAt')) {
      get(this, 'publishPagePublication').perform(pagePublication);
    } else {
      get(this, 'unpublishPagePublication').perform(pagePublication);
    }
  }

  @action
  handleDestroyPage(page: PageModel) {
    const pageTitle = get(page, 'displayTitle');

    get(this, 'ttAlert').customConfirm({
      title: this.intl.t('common.are_you_sure'),
      text: this.intl.t('content.editor.page_will_be_deleted', {
        pageTitle,
      }),

      confirmButtonText: this.intl.t('common.delete'),
      cancelButtonText: this.intl.t('common.cancel'),
      confirmCallback: () => {
        get(page, 'pagePublication')
          .destroyRecord()
          .then(async () => {
            (await this.pages).removeObject(page);
            page.unloadRecord();
            get(this, 'flashMessages').notice(this.intl.t('common.deleted'));
          });
      },

      cancelCallback: () => {},
    });
  }

  @action
  promotePage(page: PageModel, route: string) {
    const pagePublication = get(page, 'pagePublication');
    const isPagePublished = get(pagePublication, 'publishedAt');
    const pageTitle = get(page, 'displayTitle');

    if (isPagePublished) {
      this.router.transitionTo(route, page.id);
    } else {
      get(this, 'ttAlert').customConfirm({
        title: this.intl.t('content.editor.publish_first'),
        text: this.intl.t('content.editor.do_you_want_to_publish_page', {
          pageTitle,
        }),

        confirmButtonText: this.intl.t('common.yes'),
        cancelButtonText: this.intl.t('common.no'),
        confirmCallback: () => {
          pagePublication.publish().then(() => {
            this.router.transitionTo(route, page);
          });
        },

        cancelCallback: () => {},
      });
    }
  }

  @action
  handleDuplicatePage(page: PageModel) {
    page.status = 'clone';
    this.duplicatePageTask.perform(page);
  }

  declare qrCodePage: PageModel;

  @action
  handleCreateQrCode(page: PageModel) {
    set(this, 'showQrCodeCreator', true);
    set(this, 'qrCodePage', page);
  }

  @action
  handleMakeDefaultTemplate(page: PageModel) {
    this.changeTemplateTask.perform(page);
  }

  @action
  async handleNewPost() {
    const page = await this.store.createRecord('page', {
      careerSiteId: this.site,
      pageType: 'post',
      publishDate: new Date(),
      users: [this.current.user],
    });

    await page.save();

    return this.router.transitionTo('content.posts.edit', page);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'content.index': ContentIndexController;
  }
}

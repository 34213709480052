import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Input\n  ...attributes\n  class={{class-names\n    \"no-global-styles rounded-6 border border-neutral-strong bg-action-medium px-8 text-14 font-regular text-action-medium\"\n    \"focus-visible:border-transparent focus-visible:outline focus-visible:outline-2 dark:!border-0 dark:bg-neutral-medium\"\n    (if\n      @isReadonly\n      \"read-only:bg-neutral-weak read-only:text-neutral-medium focus-visible:outline-zinc-500/50\"\n      \"focus-visible:outline-focus\"\n    )\n    (if (eq @size \"medium\") \"h-32\")\n    (if (eq @size \"large\") \" h-40\")\n    w-full=@isFullWidth\n  }}\n  readonly={{@isReadonly}}\n  @value={{this.formattedValue}}\n  {{on \"blur\" this.formatInputOnBlur}}\n  {{on \"input\" this.handleInput}}\n/>", {"contents":"<Input\n  ...attributes\n  class={{class-names\n    \"no-global-styles rounded-6 border border-neutral-strong bg-action-medium px-8 text-14 font-regular text-action-medium\"\n    \"focus-visible:border-transparent focus-visible:outline focus-visible:outline-2 dark:!border-0 dark:bg-neutral-medium\"\n    (if\n      @isReadonly\n      \"read-only:bg-neutral-weak read-only:text-neutral-medium focus-visible:outline-zinc-500/50\"\n      \"focus-visible:outline-focus\"\n    )\n    (if (eq @size \"medium\") \"h-32\")\n    (if (eq @size \"large\") \" h-40\")\n    w-full=@isFullWidth\n  }}\n  readonly={{@isReadonly}}\n  @value={{this.formattedValue}}\n  {{on \"blur\" this.formatInputOnBlur}}\n  {{on \"input\" this.handleInput}}\n/>","moduleName":"teamtailor/components/core/salary-input.hbs","parseOptions":{"srcName":"teamtailor/components/core/salary-input.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { tracked } from '@glimmer/tracking';
import { localizedToNumber } from 'teamtailor/helpers/localized-to-number';
import Model from '@ember-data/model';
import { IntlService } from 'ember-intl';

export interface CoreCurrencyInputArgs {
  onInput?: (value: string) => void;
  onChange?: (value: string) => void;
  value?: number;
  model: Model;
  modelField: string;
}

export default class CoreCurrencyInputComponent extends Component<CoreCurrencyInputArgs> {
  @service declare current: Current;
  @service declare intl: IntlService;
  @tracked formattedValue?: string;

  constructor(owner: unknown, args: CoreCurrencyInputArgs) {
    super(owner, args);
    this.initializeFormattedValue();
  }

  private initializeFormattedValue() {
    if (this.args.value) {
      this.formattedValue = this.formatValue(this.args.value);
    }
  }

  private formatValue(value: number) {
    return value.toLocaleString(this.getLocale(), {
      minimumFractionDigits: 0,
      maximumFractionDigits: 10,
    });
  }

  private getLocale() {
    return this.current.locale;
  }

  private getNumericValue(value: string) {
    return localizedToNumber(value, this.getLocale());
  }

  private setFormattedValue(
    inputElement: HTMLInputElement,
    numericValue: number
  ) {
    const formattedValue = this.formatValue(numericValue);
    inputElement.value = formattedValue;
    this.args.onChange?.(numericValue.toString());
    this.updateModel(numericValue);
    this.removeErrors();
  }

  private revertToOriginalValue(inputElement: HTMLInputElement) {
    this.args.onChange?.(inputElement.value);
    this.updateModel(inputElement.value);
    this.setErrors();
  }

  private updateModel(value: any) {
    set(this.args.model, this.args.modelField as keyof Model, value);
  }

  private setErrors() {
    this.args.model.errors.add(
      this.args.modelField,
      this.intl.t('requisitions.invalid_value')
    );
  }

  private removeErrors() {
    this.args.model.errors.remove(this.args.modelField);
  }

  @action
  handleInput(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const { value } = inputElement;
    this.args.onInput?.(value);
    this.updateModel(value);
  }

  @action
  formatInputOnBlur(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    const numericValue = this.getNumericValue(inputElement.value);

    if (inputElement.value === '') return;

    if (!isNaN(numericValue)) {
      this.setFormattedValue(inputElement, numericValue);
    } else {
      this.revertToOriginalValue(inputElement);
    }
  }
}

import {
  BlockAPI,
  BlockToolConstructorOptions,
  HTMLPasteEventDetail,
  PasteEvent,
} from '@editorjs/editorjs';
// @ts-expect-error No types available, need to write our own
import Quote from '@editorjs/quote';

export default class QuoteTool extends Quote {
  blockAPI?: BlockAPI;

  constructor(args: BlockToolConstructorOptions) {
    super(args);
    const { block } = args;
    this.blockAPI = block;
  }

  static get pasteConfig() {
    return {
      tags: ['BLOCKQUOTE'],
    };
  }

  onPaste(event: PasteEvent) {
    const quoteElement = this.blockAPI?.holder.querySelector<HTMLDivElement>(
      // @ts-expect-error
      `.${this.CSS.text}`
    );

    if (quoteElement && event.type === 'tag') {
      const { data } = event.detail as HTMLPasteEventDetail;
      quoteElement.innerText = data.innerText;
    }
  }
}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"flex w-full flex-col items-start gap-24 border-b border-neutral pb-48 lg:flex-row lg:gap-40 xl:gap-[80px]\"\n>\n  <div class=\"flex w-full flex-col gap-8 lg:max-w-[224px] xl:max-w-[328px]\">\n    {{#if @title}}\n      <h2 class=\"heading-5\">{{@title}}</h2>\n      <p class=\"body-text-s text-neutral-medium\">{{@description}}</p>\n    {{else}}\n      {{yield to=\"information\"}}\n    {{/if}}\n  </div>\n  <div class=\"w-full\">\n    {{yield}}\n  </div>\n</div>", {"contents":"<div\n  class=\"flex w-full flex-col items-start gap-24 border-b border-neutral pb-48 lg:flex-row lg:gap-40 xl:gap-[80px]\"\n>\n  <div class=\"flex w-full flex-col gap-8 lg:max-w-[224px] xl:max-w-[328px]\">\n    {{#if @title}}\n      <h2 class=\"heading-5\">{{@title}}</h2>\n      <p class=\"body-text-s text-neutral-medium\">{{@description}}</p>\n    {{else}}\n      {{yield to=\"information\"}}\n    {{/if}}\n  </div>\n  <div class=\"w-full\">\n    {{yield}}\n  </div>\n</div>","moduleName":"teamtailor/components/settings/form-group.hbs","parseOptions":{"srcName":"teamtailor/components/settings/form-group.hbs"}});
import Component from '@glimmer/component';

interface Args {
  title: string;
  description: string;
}

export default class EmployeeSettingsFormGroup extends Component<Args> {}

import { HTMLPasteEventDetail, PasteEvent } from '@editorjs/editorjs';

// @ts-expect-error No types available, need to write our own
import Code from '@calumk/editorjs-codecup';

export default class CodeTool extends Code {
  static get pasteConfig() {
    return {
      tags: ['PRE', 'CODE'],
    };
  }

  onPaste(event: PasteEvent) {
    const { data } = event.detail as HTMLPasteEventDetail;
    if (event.type === 'tag') {
      // @ts-expect-error
      this.data?.editorInstance?.updateCode(data.innerText);
    }
  }
}

// @ts-nocheck
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import CustomReportSchema, {
  CustomReportOperator,
  CustomReportProperty,
  CustomReportRollups,
  CustomReportType,
} from './custom-report-schema';
import CustomReportRequestFilter, {
  deserializeValue,
  serializeValue,
} from './custom-report-request-filter';
import RSVP from 'rsvp';
import { restartableTask, timeout } from 'ember-concurrency';
import ApplicationInstance from '@ember/application/instance';

export type SerializedFilter = {
  name: string;
  operator: CustomReportOperator;
  value: any;
};

export { CustomReportRequestFilter };

export async function fetchSchema(
  store: Store,
  hideGroupCompanyAnalytics = false
) {
  const adapter = store.adapterFor('application');
  const rootUrl = adapter.buildURL('report_schemas');
  const url = `${rootUrl}?hide_group_company_analytics=${hideGroupCompanyAnalytics}`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json() as Promise<CustomReportSchema>);
}

export async function buildFromSerializedFormat(
  container: ApplicationInstance,
  store: Store,
  groupAnalytics: boolean,
  schema: CustomReportSchema,
  preview: boolean,
  value: {
    reportType: CustomReportType;
    properties: string[];
    filters: SerializedFilter[];
    rollups: CustomReportRollups;
  }
): Promise<CustomReportRequest> {
  const properties: (CustomReportProperty | undefined)[] = value.properties.map(
    (propertyName: string) =>
      schema[value.reportType].properties.findBy('id', propertyName)
  );

  const filters: (CustomReportRequestFilter | null)[] = await RSVP.all(
    value.filters.map(async (serializedFilter: SerializedFilter) => {
      const filter = schema[value.reportType].filters.findBy(
        'id',
        serializedFilter.name
      );

      if (filter) {
        return new CustomReportRequestFilter(
          filter,
          serializedFilter.operator,
          await deserializeValue(store, serializedFilter.value, groupAnalytics)
        );
      } else {
        return null;
      }
    })
  );

  return new CustomReportRequest(
    container,
    preview,
    value.reportType,
    properties.filter((property) => property !== undefined),
    filters.filter(
      (filter: CustomReportRequestFilter | null) => filter !== null
    )
  );
}

export default class CustomReportRequest {
  @service declare store: Store;

  container: ApplicationInstance;

  constructor(
    container: ApplicationInstance,
    preview: boolean,
    reportType: CustomReportType,
    properties?: CustomReportProperty[],
    filters?: CustomReportRequestFilter[]
  ) {
    this.container = container;
    this.preview = preview;
    this.reportType = reportType;
    this.properties = properties || [];
    this.filters = filters || [];
  }

  @tracked preview: boolean;
  @tracked reportType: CustomReportType;
  @tracked properties: CustomReportProperty[];
  @tracked filters: CustomReportRequestFilter[];

  get asJSON() {
    return {
      preview: this.preview,
      report_type: this.reportType,
      properties: this.properties.map((a) => a.name),
      filters: this.filters.filter((a) => a.isValid).map((a) => a.asJSON),
    };
  }

  async load(): Promise<any> {
    const adapter = this.store.adapterFor('application');
    const url = adapter.buildURL('reports');

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(this._jsonPayload),
    }).then((res) => res.json());

    return response;
  }

  get serialize(): any {
    return {
      preview: this.preview,
      reportType: this.reportType,
      properties: this.properties.map((p) => p.id),
      filters: this.filters
        .filter((filter) => filter.isValid)
        .map((filter) => ({
          name: filter.filter?.id,
          operator: filter.selectedOperator,
          value: serializeValue(filter.value),
        })),
    };
  }

  get _jsonPayload() {
    return {
      preview: this.preview,
      report_type: this.reportType,
      properties: this.properties.map((a) => a.id),
      filters: this.filters.filter((a) => a.isValid).map((a) => a.asJSON),
    };
  }

  /* eslint-disable no-await-in-loop */
  generateCustomReport = restartableTask(
    async (pollRate: number): Promise<string> => {
      const response = await this.load();

      for (;;) {
        try {
          const result = await window.fetch(response.result_url, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const { redirect_url } = (await result.json()) as {
            redirect_url: string;
          };

          return redirect_url;
        } catch (error) {
          await timeout(pollRate);
        }
      }
    }
  );
  /* eslint-enable no-await-in-loop */

  fetchCustomReportRows = restartableTask(async (pollRate: number) => {
    const redirect_url = await this.generateCustomReport.perform(pollRate);

    return window.fetch(redirect_url).then((res) => res.json());
  });
}

import Store from '@ember-data/store';
import Model, {
  hasMany,
  belongsTo,
  attr,
  AsyncBelongsTo,
  SyncHasMany,
  AsyncHasMany,
} from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { sectionNameKey } from 'teamtailor/helpers/section-name-key';
import classnames from 'teamtailor/utils/classnames';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { get } from 'teamtailor/utils/get';
import EmberObject from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import Server from 'teamtailor/services/server';
import {
  AllModels,
  BlockLayoutModel,
  CompanyModel,
  DepartmentModel,
  ImageWithSettingModel,
  JobModel,
  LocationModel,
  PickedImageModel,
  PickedVideoModel,
  PushPayloadArg,
  SectionItemModel,
} from '.';
import PageModel from './page';
import SectionChangeModel from './section-change';

const customNameMaxLength = 35;
const sectionsWithLayout = ['jobs', 'gallery', 'people', 'jobs-list', 'video'];
const disabledTemplateFunctionalities = {
  'job-ad': 'all',
  cover: ['drag', 'click', 'ren =me'],
  recruiter: ['click', 'delete', 'global'],
  colleagues: ['click', 'delete', 'global'],
  'related-jobs': ['click', 'delete', 'global'],
  posts: 'all',
} as const;

export type ColorPresets = 'primary' | 'secondary' | 'button' | 'custom';

export type AllSectionModels = Extract<AllModels, SectionModel>;

export default class SectionModel extends Model {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('block-layout', { async: false })
  declare availableLayouts: SyncHasMany<BlockLayoutModel>;

  @hasMany('picked-image', { async: false })
  declare pickedImages: SyncHasMany<PickedImageModel>;

  @hasMany('picked-video', { async: false })
  declare pickedVideos: SyncHasMany<PickedVideoModel>;

  @hasMany('section-item')
  declare sectionItems?: AsyncHasMany<SectionItemModel>;

  @belongsTo('page', { inverse: 'sections' })
  declare page: AsyncBelongsTo<PageModel>;

  @belongsTo('department')
  declare department: AsyncBelongsTo<DepartmentModel>;

  @belongsTo('location')
  declare location: AsyncBelongsTo<LocationModel>;

  @belongsTo('job') declare job: AsyncBelongsTo<JobModel>;
  @belongsTo('section', {
    async: true,
    polymorphic: true,
    inverse: 'linkedSections',
  })
  declare parent: AsyncBelongsTo<SectionModel>;

  @hasMany('section', { async: true, polymorphic: true, inverse: 'parent' })
  declare linkedSections: AsyncHasMany<SectionModel>;

  @belongsTo('image-with-setting', { async: false })
  declare imageWithSetting: ImageWithSettingModel;

  @belongsTo('section-change')
  declare sectionChange: AsyncBelongsTo<SectionChangeModel>;

  @attr('string') declare animationId: string;
  @attr('string') declare jobHighlights?: string;
  @attr('number') declare buttonCount: number;
  @attr('string') declare body: string;
  @attr('string') declare upperNote: string;
  @attr('string') declare buttonText: string;
  @attr('string') declare coverOverlayColor: string;
  @attr('number') declare coverOverlayOpacity: number;
  @attr('string') declare coverTextColor: string;
  @attr('string') declare coverBackgroundColor: string;
  @attr('number') declare ctaPageId: number;
  @attr('string') declare ctaType: string;
  @attr('boolean') declare ctaWindowTargetBlank: boolean;
  @attr('string') declare customUrl: string;
  @attr('string') declare wrapperClass: string | null;
  @attr('boolean', { defaultValue: false }) declare hasCustomClassName: boolean;
  @attr('boolean') declare enabled: boolean;
  @attr('boolean') declare globalSection: boolean;
  @attr('boolean') declare hasHeroCtaButton: boolean;
  @attr('string') declare header: string;
  @attr('string') declare heroCtaButtonText: string;
  @attr('boolean', { defaultValue: true }) declare interactiveMap: boolean;
  @attr('boolean', { defaultValue: true }) declare interactiveMapSplit: boolean;
  @attr('boolean') declare jobsListGroupCompanies: boolean;
  @attr('raw') declare jobsListFilterSettings: Record<string, unknown>;
  @attr('string') declare layout: string;
  @attr('boolean') declare linkToApplication: boolean;
  @attr('string') declare name: string;

  @attr('string') declare pickedDepartmentId: string | undefined;
  @attr('string') declare pickedLocationId: string | undefined;
  @attr('string') declare pickedLanguage: string | undefined;
  @attr('number') declare rowOrder: number;
  @attr('number') declare rowOrderPosition: number;
  @attr('string') declare type: string;
  @attr('boolean', { defaultValue: false })
  declare useCoverImageOverLogos: boolean;

  @attr('string') declare video: string;
  @attr('string') declare customName: string;
  @attr('raw') declare items?: SectionItemModel[];

  @attr('string') declare customIconColor?: string;
  @attr('string') declare customBackgroundColor?: string;
  @attr('string') declare customTitleColor?: string;
  @attr('string') declare customTextColor?: string;
  @attr('string') declare customLinkColor?: string;
  @attr('string') declare customHighlightColor?: string;
  @attr('string') declare customImageBackground: string;
  @attr('string') declare customShadowType: string;
  @attr('string') declare customTitleType: string;
  @attr('string', { defaultValue: null })
  declare customEmployeesShape: string;

  @attr('string') declare backgroundColor: string;
  @attr('string') declare titleColor: string;
  @attr('string') declare textColor: string;
  @attr('string') declare linkColor: string;
  @attr('string') declare highlightColor: string;
  @attr('string') declare imageBackground: string;
  @attr('string') declare shadow: string;
  @attr('string') declare titleType: string;
  @attr('string') declare employeesShape: string;

  @attr('string') declare colorPreset: ColorPresets;
  @attr('boolean', { defaultValue: false }) declare reverseOrder: boolean;
  @attr('boolean', { allowNull: true, defaultValue: true })
  declare animated: boolean;

  @attr('boolean', { allowNull: true }) declare abbreviated: boolean;

  get pickedDepartment() {
    return this.pickedDepartmentId
      ? this.store.findRecord('department', this.pickedDepartmentId)
      : null;
  }

  get pageId() {
    return get(get(this, 'page'), 'id');
  }

  get hasInvalidAssociations() {
    return get(this, 'sectionItems')
      ?.slice()
      .some((sectionItem: SectionItemModel) => {
        return !get(sectionItem, 'isValid');
      });
  }

  get hasDirtyAssociations() {
    return this.hasDirtyPickedImages || this.hasDirtySectionItems;
  }

  get hasParent() {
    return !!get(get(this, 'parent'), 'id');
  }

  get hasUnpublishedChanges() {
    return !!get(get(this, 'sectionChange'), 'id');
  }

  get disabledFunctionalities() {
    if (
      get(get(this, 'page'), 'isTemplatePage') ||
      get(get(this, 'page'), 'isPostListPage')
    ) {
      return disabledTemplateFunctionalities[
        this.name as keyof typeof disabledTemplateFunctionalities
      ];
    }

    return null;
  }

  get isDragable() {
    return (
      get(this, 'name') !== 'cover' && !this.isFunctionalityDisabled('drag')
    );
  }

  get isUnmodifiable() {
    return this.disabledFunctionalities === 'all';
  }

  get areSettingsEditable() {
    return !this.isFunctionalityDisabled('click');
  }

  get isRenameable() {
    return !this.isFunctionalityDisabled('rename');
  }

  get isHideable() {
    return !this.isFunctionalityDisabled('hide');
  }

  get isDeletable() {
    return !this.isFunctionalityDisabled('delete');
  }

  isFunctionalityDisabled(
    functionality: 'click' | 'delete' | 'global' | 'hide' | 'rename' | 'drag'
  ) {
    return (
      this.disabledFunctionalities &&
      (this.disabledFunctionalities === 'all' ||
        this.disabledFunctionalities.includes(functionality as 'click'))
    );
  }

  get isLinked() {
    return get(this, 'name') === 'linked';
  }

  get isCover() {
    return get(this, 'name') === 'cover';
  }

  get hasLayout() {
    return sectionsWithLayout.includes(get(this, 'name'));
  }

  get canBeGlobal() {
    if (this.globalDisabled) {
      return false;
    }

    return !this.isLinked || this.hasParent;
  }

  get useCustomName() {
    return this.customName && this.name !== 'job-ad';
  }

  get prettyCustomName() {
    return this.customName.length > customNameMaxLength
      ? `${this.customName.substring(0, customNameMaxLength - 3)}...`
      : this.customName;
  }

  get prettyName(): string {
    return this.useCustomName
      ? this.prettyCustomName
      : this.hasParent
        ? get(get(this, 'parent'), 'prettyName')
        : this.intl.t(sectionNameKey(this.name));
  }

  get globalDisabled() {
    return (
      ['share-links'].includes(this.name) ||
      this.isFunctionalityDisabled('global')
    );
  }

  get description() {
    const name = get(this, 'name');

    if (name === 'linked') {
      return get(get(this, 'parent'), 'header');
    }

    if (
      name === 'text' ||
      name === 'linked' ||
      name === 'cta' ||
      name === 'timeline' ||
      name === 'list' ||
      name === 'value' ||
      name === 'grid'
    ) {
      const header = get(this, 'header');
      const body = get(this, 'body');
      if (!isEmpty(header)) {
        return header;
      } else if (!isEmpty(body)) {
        return get(this, 'body')
          .trim()
          .replace(/(<([^>]+)>)/gi, ' ')
          .slice(0, 50);
      }
    }

    return undefined;
  }

  calculateIndex() {
    return (
      get(get(this, 'company'), 'sections').filter(
        (s) => get(s, 'rowOrder') < get(this, 'rowOrder')
      ).length + 2
    );
  }

  get index() {
    return this.calculateIndex();
  }

  set index(_value) {
    // noop
  }

  get dragableClassName() {
    return classnames(
      'dragable-item',
      'editor-section',
      'editor-section--is-dragable',
      'editor-section--clickable',
      {
        'editor-section--disabled': !this.enabled,
      }
    );
  }

  get className() {
    return get(this, 'enabled')
      ? 'editor-section editor-section--clickable'
      : 'editor-section editor-section--clickable editor-section--disabled';
  }

  get sortedPickedImages() {
    const existingImages = get(this, 'pickedImages')
      .rejectBy('isNew', true)
      .sortBy('rowOrder');
    const newImages = get(this, 'pickedImages').filterBy('isNew', true);
    return [...existingImages, ...newImages];
  }

  get hasDirtyPickedImages() {
    return get(this, 'pickedImages').some((pickedImage: PickedImageModel) =>
      get(pickedImage, 'hasDirtyAttributes')
    );
  }

  get hasDirtySectionItems() {
    return get(this, 'sectionItems')
      ?.slice()
      .some((sectionItem: SectionItemModel) => {
        return (
          get(sectionItem, 'hasDirtyAttributes') ||
          !!get(sectionItem, 'dirtyType')
        );
      });
  }

  get sortedItems() {
    return get(this, 'sectionItems')?.sortBy('position');
  }

  get canBeDeleted() {
    if (this.globalSection) return false;
    else if (
      get(get(this, 'page'), 'isDepartmentsListPage') &&
      this.name === 'departments-list'
    )
      return false;
    else if (
      get(get(this, 'page'), 'isLocationsListPage') &&
      this.name === 'locations-list'
    )
      return false;
    else return true;
  }

  get layoutPath() {
    const sectionName = get(this, 'name');
    const routeName = `content.editor.section.${sectionName}.layout`;
    // https://stackoverflow.com/a/52655828/15648495
    if (getOwner(this).lookup(`route:${routeName}`)) {
      return routeName;
    } else {
      return 'content.editor.section.layout';
    }
  }

  get itemsArray() {
    const array = [] as SectionItemModel[];

    if (!get(this, 'items')?.length) return undefined;

    get(this, 'items')?.forEach((item) => {
      array.pushObject(EmberObject.create(item));
    });

    return array.sortBy('rowOrderPosition');
  }

  async markAsGlobal(value?: string | Record<string, unknown>) {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'global',
      method: 'POST',
      options: {
        data: value,
      },
    });
    this.store.pushPayload(response);
  }

  async unlinkSection(value?: string | Record<string, unknown>) {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'link',
      method: 'DELETE',
      options: {
        data: value,
      },
    });
    this.unloadRecord();
    this.store.pushPayload(response);
  }

  async setCustomName(value?: string | Record<string, unknown>) {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'custom_name',
      options: { data: value },
    });
    this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    section: SectionModel;
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class EnmployeeDashboardWidgetEditRoute extends Route {
  @service user;
  @service current;
  @service intl;
  @service router;
  @service store;

  model() {
    if (!get(this, 'user.admin')) {
      this.router.transitionTo('employee.index');
    }

    if (get(this.current.company, 'employeeDashboard.content')) {
      return get(this.current.company, 'employeeDashboard');
    }

    return this.store.createRecord('employeeDashboard', {
      company: get(this.current.company, '_internalModel'),
      introMessage:
        this.intl.t('employee.lets_work_together') +
        '\n' +
        this.intl.t('employee.make_first_referral_description'),
    });
  }

  resetController(controller) {
    const model = get(controller, 'model');
    const changeset = get(controller, 'changeset');

    if (model.hasDirtyAttributes && model.isNew) {
      return model.destroyRecord();
    }

    if (changeset.isDirty) {
      return changeset.rollback();
    }

    return get(this.current.company, 'employeeDashboard');
  }
}
